/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';

// Additional Presets & Variables
@import 'presets/grid-settings';
@import 'presets/helper-classes';

// Components
@import 'components/hp-banners';

.homepage {
  main {
    overflow-x: hidden;
  }

  [class*='section-'] {
    position: relative;
    padding: 0 0 70px 0;
    width: 100%;

    .inner {
      display: flex;
      flex-wrap: wrap;
      @include large-up {
        flex-wrap: no-wrap;
        margin: 0 auto;
        max-width: 1760px;
      }
    }

    &.section-full {
      padding: 70px 30px;
      @include large-up {
        // display: flex;
        padding: 100px 80px;

        h2 {
          padding: 60px 100px 30px 0;
          width: 40%;
          text-align: right;
          background: url('../images/ICN-Logo-Faint@2x.png') center right / contain no-repeat;
        }

        .inner > div {
          align-self: flex-end;
          padding-left: 40px;
          width: 60%;
        }

        p {
          @include large-up {
            @include fontsize(24 36);
          }
        }
      }
      @include xxlarge-up {
        margin: 0 auto;
        padding-left: 80px;
        padding-right: 80px;
        max-width: 1760px;
      }
      @include mq(1601px) {
        // padding-left: 0;
        // padding-right: 0;
      }
    }

    // 54%
    // 46%

    .content {
      order: 2;
      padding-left: 30px;
      padding-right: 30px;
      width: 100%;
      @include large-up {
        display: inline-flex;
        align-items: center;
        padding: 0 0 0 80px;
        width: 40%;
      }
      @include xxlarge-up {
        width: 46%;
      }
      @include mq(1601px) {
        // padding-left: 0;
      }
    }

    .img {
      position: relative;
      order: 1;
      width: 100%;
      @include large-up {
        width: 60%;
      }
      @include xxlarge-up {
        width: 54%;
      }

      p {
        margin: 0;
      }

      img {
        position: relative;
        margin-bottom: 50px;
        width: 164%;
        max-width: 164%;
        @include medium-up {
          width: 110%;
          max-width: 110%;
        }
        @include large-up {
          margin: 0;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  [class*='-dark'],
  [class*='-red'] {
    color: white;
    background: $codgray;

    h2,
    p {
      color: white;
    }
  }

  [class*='-dark'] {
    border-bottom: 1px solid white;
    @include large-up {
      padding: 100px 0;
      border: 0;
    }

    @at-root .dnnEditState .section-dark{
      background: white !important;

      h2,
      p {
        color: black !important;
      }
    }

    .img {
      img {
        left: -35%;
        @include medium-up {
          // left: -17%;
          left: auto;
          float: right;
        }
        @include large-up {
          // left: -41%;
          width: 154%;
          max-width: 868px;
        }
        @include xlarge-up {
          // left: -13%;
        }
        @include xxlarge-up {
          padding-left: 80px;
          left: auto;
          width: auto;
          max-width: 100%;
        }
      }
    }

    .content {
      @include large-up {
        padding-left: 40px;
        padding-right: 80px;
        text-align: right;
      }
      @include mq(1601px) {
        // padding-right: 0;
      }
    }

    .btn-primary,
    a.btn-primary {
      &:hover,
      &:focus {
        color: $codgray;
        background: white;
      }
    }
  }

  [class*='-white']:not(.section-full) {
    @include large-up {
      padding: 100px 0;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 172px 80px 0 0;
        border-color: rgba($codgray, 0.05) transparent transparent transparent;
        content: '';
      }
    }

    .img {
      @include large-up {
        order: 2;
      }
      @include xxlarge-up {
        padding-right: 80px;
      }

      img {
        left: -38%;
        max-width: 167%;
        width: 167%;
        @include medium-up {
          left: auto;
          right: -5%;
          width: 110%;
          max-width: 110%;
        }
        @include large-up {
          left: auto;
          right: 0;
          width: 154%;
          max-width: 868px;
        }
        @include xxlarge-up {
          //   padding-right: 80px;
          width: auto;
          max-width: 100%;
        }
      }
    }

    .content {
      @include large-up {
        order: 1;
      }
      @include xxlarge-up {
        padding-left: 80px;
      }
    }
  }

  [class*='-red'] {
    background: $monza;
  }

  .locator {
    background: url('../images/bg-locator-hp.jpg') center center / cover no-repeat;
    @include large-up {
      background: url('../images/bg-locator-hp@2x.jpg') center center / cover no-repeat;
    }
  }

  .card {
  }

  .promo {
    position: relative;
    padding-top: 85px;
    padding-bottom: 165px;
    text-shadow: 1px 0px 6px rgba(black, 0.3);
    background: url('../images/bg-promo-hp.jpg') center right / cover no-repeat;
    @include large-up {
      padding-top: 80px;
      padding-bottom: 80px;
      background: url('../images/bg-promo-hp@2x.jpg') center center / cover no-repeat;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      background-color: rgba($codgray, 0.15);
      background-image: url('../images/ICN-Arrow.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 30px 27px;
      content: '';
      @include large-up {
        top: 50%;
        bottom: auto;
        left: auto;
        right: 80px;
        width: 55px;
        height: 50px;
        background-color: transparent;
        background-size: cover;
        transform: translateY(-50%);
        transition: right 0.2s ease-in-out;
      }
    }

    .content {
      @include large-up {
        padding-right: 215px;
        width: 100%;
      }
    }

    p {
      @include fontsize(18 18);
      @include xlarge-up {
        @include fontsize(24 24);
      }

      &:last-of-type {
        margin: 0;
      }
    }

    .btn-primary {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-indent: -9999rem;
      background: transparent;
      border: 0;
    }

    &:hover,
    &:focus {
      &:after {
        right: 40px;
        background-image: url('../images/ICN-Arrow-White.svg');
      }
    }
  }

  .brand {
    background: url('../images/bg-brand-hp.jpg') center center / cover no-repeat;
    @include large-up {
      background: url('../images/bg-brand-hp@2x.jpg') center center / cover no-repeat;
    }
  }
}

.dnnEditState {
  .wrap-banners {
    overflow: hidden !important;
  }

  [class*='section'] .img img {
    left: auto;
    right: auto;
    width: auto !important;
    max-width: 100% !important;
  }
}