/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block !important;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none !important;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block !important;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none !important;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none !important;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block !important;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none !important;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block !important;
  }
}

@media (min-width: 640px) {
  .show-small-only {
    display: none !important;
  }
}

@media (min-width: 0) and (max-width: 640px) {
  .show-medium-only {
    display: none !important;
  }
}
@media (min-width: 1024px) {
  .show-medium-only {
    display: none !important;
  }
}

.show-large-only {
  display: none !important;
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .show-large-only {
    display: block !important;
  }
}
@media (min-width: 1280px) {
  .show-large-only {
    display: none !important;
  }
}

.show-xlarge-only {
  display: none !important;
}
@media (min-width: 1280px) and (max-width: 1599px) {
  .show-xlarge-only {
    display: block !important;
  }
}
@media (min-width: 1600px) {
  .show-xlarge-only {
    display: none !important;
  }
}

.show-xxlarge-only {
  display: none !important;
}
@media (min-width: 1600px) {
  .show-xxlarge-only {
    display: none !important;
  }
}

.show-medium-up {
  display: none !important;
}
@media (min-width: 640px) {
  .show-medium-up {
    display: block !important;
  }
}

.show-large-up {
  display: none !important;
}
@media (min-width: 1024px) {
  .show-large-up {
    display: block !important;
  }
}

.show-xlarge-up {
  display: none !important;
}
@media (min-width: 1280px) {
  .show-xlarge-up {
    display: block !important;
  }
}

.show-xxlarge-up {
  display: none !important;
}
@media (min-width: 1600px) {
  .show-xxlarge-up {
    display: block !important;
  }
}

@media (min-width: 0) and (max-width: 640px) {
  .hide-small-only {
    display: none !important;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .hide-medium-only {
    display: none !important;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .hide-large-only {
    display: none !important;
  }
}

@media (min-width: 1280px) and (max-width: 1599px) {
  .hide-xlarge-only {
    display: none !important;
  }
}

@media (min-width: 1600px) {
  .hide-xxlarge-only {
    display: none !important;
  }
}

@media (min-width: 640px) {
  .hide-medium-up {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .hide-large-up {
    display: none !important;
  }
}

@media (min-width: 1280px) {
  .hide-xlarge-up {
    display: none !important;
  }
}

@media (min-width: 1600px) {
  .hide-xxlarge-up {
    display: none !important;
  }
}

.wrap-banners {
  position: relative;
}

.banners {
  position: relative;
  background: url("../images/bg-banner-hp.jpg") center center/cover no-repeat;
  height: 630px;
}
@media (min-width: 1024px) {
  .banners {
    height: 380px;
  }
}
@media (min-width: 1280px) {
  .banners {
    height: 640px;
  }
}
.banners .banner {
  height: 630px;
  width: 100%;
}
@media (min-width: 1024px) {
  .banners .banner {
    height: 380px;
  }
}
@media (min-width: 1280px) {
  .banners .banner {
    margin-bottom: 40px;
    height: 640px;
  }
}
.banners .banner.cycle-sentinel {
  display: none;
}
.banners .banner-img {
  height: 260px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (min-width: 640px) {
  .banners .banner-img {
    height: 370px;
  }
}
@media (min-width: 1024px) {
  .banners .banner-img {
    position: absolute;
    width: 76%;
    height: calc(100% + 40px);
    background-position: center right;
  }
}
@media (min-width: 1280px) {
  .banners .banner-img {
    width: 72%;
  }
}
@media (min-width: 1280px) {
  .banners .banner-img {
    width: 65%;
  }
}
@media (min-width: 1024px) {
  .banners .inner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    max-width: 1760px;
  }
}
.banners .text {
  padding: 40px 30px 100px;
}
@media (min-width: 1024px) {
  .banners .text {
    position: absolute;
    top: 50%;
    right: 0;
    padding: 0 25px 0 0;
    width: 27%;
    text-align: right;
    transform: translateY(-50%);
  }
}
@media (min-width: 1280px) {
  .banners .text {
    padding: 0 80px 0 0;
    width: 34%;
  }
}
.banners h3 {
  margin: 0 0 10px 0;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 36px;
  line-height: 30px;
  font-size: 2.25rem;
  line-height: 1.875rem;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: white;
}
@media (min-width: 1024px) {
  .banners h3 {
    font-size: 30px;
    line-height: 32px;
    font-size: 1.875rem;
    line-height: 2rem;
    letter-spacing: 0.015em;
  }
}
@media (min-width: 1280px) {
  .banners h3 {
    font-size: 48px;
    line-height: 42px;
    font-size: 3rem;
    line-height: 2.625rem;
    letter-spacing: 0.015em;
  }
}
.banners p {
  font-size: 18px;
  line-height: 24px;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  color: white;
}
@media (min-width: 1024px) {
  .banners p {
    font-size: 16px;
    line-height: 22px;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: normal;
  }
}
@media (min-width: 1280px) {
  .banners p {
    font-size: 18px;
    line-height: 24px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: normal;
  }
}
.banners .btn-primary {
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.05em;
  color: white;
  background: transparent;
  border-bottom: 2px solid #d50032;
}
@media (min-width: 1024px) {
  .banners .btn-primary {
    transition: border-color 0.2s ease-in-out;
  }
}
.banners .btn-primary:visited {
  background: transparent;
  border-color: #d50032;
  color: white;
}
.banners .btn-primary:active {
  background: transparent;
  border-color: #d50032;
  color: white;
}
.banners .btn-primary:hover, .banners .btn-primary:focus {
  color: #d50032;
  background: transparent;
  border-color: white;
}

.banner-pager {
  position: absolute;
  bottom: 60px;
  left: 30px;
  z-index: 150;
}
@media (min-width: 1024px) {
  .banner-pager {
    left: 0;
    right: 0;
    bottom: -44px;
    margin: 0 auto;
    padding: 0 80px;
    width: 100%;
    text-align: right;
  }
}
@media (min-width: 1280px) {
  .banner-pager {
    max-width: 1760px;
  }
}
.banner-pager span {
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  width: 9px;
  height: 20px;
  background: rgba(255, 255, 255, 0.3);
  transform: skew(-30deg, 0deg);
}
@media (min-width: 1024px) {
  .banner-pager span {
    margin-right: 15px;
    width: 18px;
    height: 80px;
    background: rgba(26, 26, 26, 0.3);
    transition: background 0.2s ease-in-out;
  }
}
.banner-pager span:hover, .banner-pager span:focus {
  background: white;
}
@media (min-width: 1024px) {
  .banner-pager span:hover, .banner-pager span:focus {
    background: rgba(26, 26, 26, 0.6);
  }
}
.banner-pager span.cycle-pager-active {
  background: #d50032;
}
.banner-pager span.cycle-pager-active:hover, .banner-pager span.cycle-pager-active:focus {
  color: #d50032;
}

.homepage main {
  overflow-x: hidden;
}
.homepage [class*=section-] {
  position: relative;
  padding: 0 0 70px 0;
  width: 100%;
}
.homepage [class*=section-] .inner {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .homepage [class*=section-] .inner {
    flex-wrap: no-wrap;
    margin: 0 auto;
    max-width: 1760px;
  }
}
.homepage [class*=section-].section-full {
  padding: 70px 30px;
}
@media (min-width: 1024px) {
  .homepage [class*=section-].section-full {
    padding: 100px 80px;
  }
  .homepage [class*=section-].section-full h2 {
    padding: 60px 100px 30px 0;
    width: 40%;
    text-align: right;
    background: url("../images/ICN-Logo-Faint@2x.png") center right/contain no-repeat;
  }
  .homepage [class*=section-].section-full .inner > div {
    align-self: flex-end;
    padding-left: 40px;
    width: 60%;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  .homepage [class*=section-].section-full p {
    font-size: 24px;
    line-height: 36px;
    font-size: 1.5rem;
    line-height: 2.25rem;
    letter-spacing: normal;
  }
}
@media (min-width: 1600px) {
  .homepage [class*=section-].section-full {
    margin: 0 auto;
    padding-left: 80px;
    padding-right: 80px;
    max-width: 1760px;
  }
}
.homepage [class*=section-] .content {
  order: 2;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}
@media (min-width: 1024px) {
  .homepage [class*=section-] .content {
    display: inline-flex;
    align-items: center;
    padding: 0 0 0 80px;
    width: 40%;
  }
}
@media (min-width: 1600px) {
  .homepage [class*=section-] .content {
    width: 46%;
  }
}
.homepage [class*=section-] .img {
  position: relative;
  order: 1;
  width: 100%;
}
@media (min-width: 1024px) {
  .homepage [class*=section-] .img {
    width: 60%;
  }
}
@media (min-width: 1600px) {
  .homepage [class*=section-] .img {
    width: 54%;
  }
}
.homepage [class*=section-] .img p {
  margin: 0;
}
.homepage [class*=section-] .img img {
  position: relative;
  margin-bottom: 50px;
  width: 164%;
  max-width: 164%;
}
@media (min-width: 640px) {
  .homepage [class*=section-] .img img {
    width: 110%;
    max-width: 110%;
  }
}
@media (min-width: 1024px) {
  .homepage [class*=section-] .img img {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
}
.homepage [class*=-dark],
.homepage [class*=-red] {
  color: white;
  background: #1a1a1a;
}
.homepage [class*=-dark] h2,
.homepage [class*=-dark] p,
.homepage [class*=-red] h2,
.homepage [class*=-red] p {
  color: white;
}
.homepage [class*=-dark] {
  border-bottom: 1px solid white;
}
@media (min-width: 1024px) {
  .homepage [class*=-dark] {
    padding: 100px 0;
    border: 0;
  }
}
.dnnEditState .section-dark {
  background: white !important;
}
.dnnEditState .section-dark h2,
.dnnEditState .section-dark p {
  color: black !important;
}

.homepage [class*=-dark] .img img {
  left: -35%;
}
@media (min-width: 640px) {
  .homepage [class*=-dark] .img img {
    left: auto;
    float: right;
  }
}
@media (min-width: 1024px) {
  .homepage [class*=-dark] .img img {
    width: 154%;
    max-width: 868px;
  }
}
@media (min-width: 1600px) {
  .homepage [class*=-dark] .img img {
    padding-left: 80px;
    left: auto;
    width: auto;
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  .homepage [class*=-dark] .content {
    padding-left: 40px;
    padding-right: 80px;
    text-align: right;
  }
}
.homepage [class*=-dark] .btn-primary:hover, .homepage [class*=-dark] .btn-primary:focus,
.homepage [class*=-dark] a.btn-primary:hover,
.homepage [class*=-dark] a.btn-primary:focus {
  color: #1a1a1a;
  background: white;
}
@media (min-width: 1024px) {
  .homepage [class*=-white]:not(.section-full) {
    padding: 100px 0;
  }
  .homepage [class*=-white]:not(.section-full):before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 172px 80px 0 0;
    border-color: rgba(26, 26, 26, 0.05) transparent transparent transparent;
    content: "";
  }
}
@media (min-width: 1024px) {
  .homepage [class*=-white]:not(.section-full) .img {
    order: 2;
  }
}
@media (min-width: 1600px) {
  .homepage [class*=-white]:not(.section-full) .img {
    padding-right: 80px;
  }
}
.homepage [class*=-white]:not(.section-full) .img img {
  left: -38%;
  max-width: 167%;
  width: 167%;
}
@media (min-width: 640px) {
  .homepage [class*=-white]:not(.section-full) .img img {
    left: auto;
    right: -5%;
    width: 110%;
    max-width: 110%;
  }
}
@media (min-width: 1024px) {
  .homepage [class*=-white]:not(.section-full) .img img {
    left: auto;
    right: 0;
    width: 154%;
    max-width: 868px;
  }
}
@media (min-width: 1600px) {
  .homepage [class*=-white]:not(.section-full) .img img {
    width: auto;
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  .homepage [class*=-white]:not(.section-full) .content {
    order: 1;
  }
}
@media (min-width: 1600px) {
  .homepage [class*=-white]:not(.section-full) .content {
    padding-left: 80px;
  }
}
.homepage [class*=-red] {
  background: #d50032;
}
.homepage .locator {
  background: url("../images/bg-locator-hp.jpg") center center/cover no-repeat;
}
@media (min-width: 1024px) {
  .homepage .locator {
    background: url("../images/bg-locator-hp@2x.jpg") center center/cover no-repeat;
  }
}
.homepage .promo {
  position: relative;
  padding-top: 85px;
  padding-bottom: 165px;
  text-shadow: 1px 0px 6px rgba(0, 0, 0, 0.3);
  background: url("../images/bg-promo-hp.jpg") center right/cover no-repeat;
}
@media (min-width: 1024px) {
  .homepage .promo {
    padding-top: 80px;
    padding-bottom: 80px;
    background: url("../images/bg-promo-hp@2x.jpg") center center/cover no-repeat;
  }
}
.homepage .promo:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: rgba(26, 26, 26, 0.15);
  background-image: url("../images/ICN-Arrow.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 27px;
  content: "";
}
@media (min-width: 1024px) {
  .homepage .promo:after {
    top: 50%;
    bottom: auto;
    left: auto;
    right: 80px;
    width: 55px;
    height: 50px;
    background-color: transparent;
    background-size: cover;
    transform: translateY(-50%);
    transition: right 0.2s ease-in-out;
  }
}
@media (min-width: 1024px) {
  .homepage .promo .content {
    padding-right: 215px;
    width: 100%;
  }
}
.homepage .promo p {
  font-size: 18px;
  line-height: 18px;
  font-size: 1.125rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
@media (min-width: 1280px) {
  .homepage .promo p {
    font-size: 24px;
    line-height: 24px;
    font-size: 1.5rem;
    line-height: 1.5rem;
    letter-spacing: normal;
  }
}
.homepage .promo p:last-of-type {
  margin: 0;
}
.homepage .promo .btn-primary {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-indent: -9999rem;
  background: transparent;
  border: 0;
}
.homepage .promo:hover:after, .homepage .promo:focus:after {
  right: 40px;
  background-image: url("../images/ICN-Arrow-White.svg");
}
.homepage .brand {
  background: url("../images/bg-brand-hp.jpg") center center/cover no-repeat;
}
@media (min-width: 1024px) {
  .homepage .brand {
    background: url("../images/bg-brand-hp@2x.jpg") center center/cover no-repeat;
  }
}

.dnnEditState .wrap-banners {
  overflow: hidden !important;
}
.dnnEditState [class*=section] .img img {
  left: auto;
  right: auto;
  width: auto !important;
  max-width: 100% !important;
}