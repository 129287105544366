.wrap-banners {
  position: relative;
}

.banners {
  position: relative;
  background: url('../images/bg-banner-hp.jpg') center center / cover no-repeat;
  height: 630px;
  // @include medium-up {
  //   height: 539px;
  // }

  @include large-up {
    // margin-bottom: 40px;
    height: 380px;
  }

  @include xlarge-up {
    // margin-bottom: 40px;
    height: 640px;
  }

  .banner {
    height: 630px;
    width: 100%;
    // @include medium-up {
    //   height: 539px;
    // }
    @include large-up {
      // margin-bottom: 40px;
      height: 380px;
    }

    @include xlarge-up {
      margin-bottom: 40px;
      height: 640px;
    }

    &.cycle-sentinel {
      display: none;
    }
  }

  .banner-img {
    height: 260px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @include medium-up {
      height: 370px;
    }

    @include large-up {
      position: absolute;
      width: 76%;
      height: calc(100% + 40px);
      background-position: center right;
    }
    @include xlarge-up {
      width: 72%;
    }
    @include xlarge-up {
      width: 65%;
    }
  }

  .inner {
    @include large-up {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      max-width: 1760px;
    }
  }

  .text {
    padding: 40px 30px 100px;

    @include large-up {
      position: absolute;
      top: 50%;
      right: 0;
      padding: 0 25px 0 0;
      width: 27%;
      text-align: right;
      transform: translateY(-50%);
    }

    @include xlarge-up {
      padding: 0 80px 0 0;
      width: 34%;
    }
  }

  h3 {
    margin: 0 0 10px 0;
    @include franklin-heavy;
    @include fontsize(36 30 15);
    text-transform: uppercase;
    color: white;
    @include large-up {
      @include fontsize(30 32 15);
    }

    @include xlarge-up {
      @include fontsize(48 42 15);
    }
  }

  p {
    @include fontsize(18 24);
    color: white;

    @include large-up {
      @include fontsize(16 22);
    }

    @include xlarge-up {
      @include fontsize(18 24);
    }
  }

  .btn-primary {
    padding: 0;
    @include franklin-heavy;
    @include fontsize(14 24 50);
    color: white;
    background: transparent;
    border-bottom: 2px solid $monza;
    @include large-up {
      transition: border-color 0.2s ease-in-out;
    }

    &:visited {
      background: transparent;
      border-color: $monza;
      color: white;
    }

    &:active {
      background: transparent;
      border-color: $monza;
      color: white;
    }

    &:hover,
    &:focus {
      color: $monza;
      background: transparent;
      border-color: white;
    }
  }
}

.banner-pager {
  position: absolute;
  bottom: 60px;
  left: 30px;
  z-index: 150;
  @include large-up {
    left: 0;
    right: 0;
    bottom: -44px;
    margin: 0 auto;
    padding: 0 80px;
    width: 100%;
    text-align: right;
  }
  @include xlarge-up {
    max-width: 1760px;
  }

  span {
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    width: 9px;
    height: 20px;
    background: rgba(white, 0.3);
    transform: skew(-30deg, 0deg);
    @include large-up {
      margin-right: 15px;
      width: 18px;
      height: 80px;
      background: rgba($codgray, 0.3);
      transition: background 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
      background: white;
      @include large-up {
        background: rgba($codgray, 0.6);
      }
    }

    &.cycle-pager-active {
      background: $monza;

      &:hover,
      &:focus {
        color: $monza;
      }
    }
  }
}