$base-font-size: 100%;
// assets path
$assets: '/Portals/_default/Skins/SC/assets/dist';

// colors
$white: #ffffff;
$tundora: #404040;
$tundora_2: #4d4d4d;
$silverchalice: #b2b2b2;
$silver: #cccccc;
$mercury: #e6e6e6;
$codgray: #1a1a1a;
$monza: #d50032;
$shiraz: #b9032e;
$orangepeel: #ffa200;

// fonts
@mixin franklin-light-it {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin franklin-book {
  font-family: 'Roboto',sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin franklin-med {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin franklin-med-it {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin franklin-demi {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin franklin-heavy {
  font-family: 'Roboto',sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin franklin-cond-book {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin franklin-cond-med {
  font-family: 'Roboto Condensed',sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin franklin-cond-demi {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-style: normal;
}

